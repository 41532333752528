import { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthGuard } from './components/authentication/auth-guard'
import { GuestGuard } from './components/authentication/guest-guard'
import { LoadingScreen } from './components/loading-screen'
import { MainLayout } from './components/main-layout'

const Loadable = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    )

// Account
const Account = Loadable(lazy(() => import('./pages/account/index')))

// Authentication
const Login = Loadable(lazy(() => import('./pages/authentication/login')))
const Register = Loadable(lazy(() => import('./pages/authentication/register')))
const PasswordRecovery = Loadable(
    lazy(() => import('./pages/authentication/password-recovery'))
)
const PasswordReset = Loadable(
    lazy(() => import('./pages/authentication/password-reset'))
)

// Customer
const CustomerEdit = Loadable(lazy(() => import('./pages/customers/edit')))
const CustomerIndex = Loadable(lazy(() => import('./pages/customers/index')))

// Device Info
const DeviceInfo = Loadable(lazy(() => import('./pages/device-info/index')))

// Order
const Orders = Loadable(lazy(() => import('./pages/orders/index')))

// Error
const AuthRequired = Loadable(lazy(() => import('./pages/401')))
const NotFound = Loadable(lazy(() => import('./pages/404')))
const ServerError = Loadable(lazy(() => import('./pages/500')))

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to="/orders" replace />,
    },
    {
        path: 'account',
        element: (
            <AuthGuard>
                <MainLayout>
                    <Account />,
                </MainLayout>
            </AuthGuard>
        ),
    },
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <MainLayout>
                            <Login />
                        </MainLayout>
                    </GuestGuard>
                ),
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />,
            },
            {
                path: 'reset-password',
                element: <PasswordReset />,
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <MainLayout>
                            <Register />
                        </MainLayout>
                    </GuestGuard>
                ),
            },
        ],
    },
    {
        path: 'customers',
        element: (
            <AuthGuard role="Admin">
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <CustomerIndex />,
            },
            {
                path: ':customerId/edit',
                element: <CustomerEdit />,
            },
        ],
    },
    {
        path: 'device-info',
        element: (
            <MainLayout>
                <DeviceInfo />,
            </MainLayout>
        ),
    },
    {
        path: 'orders',
        element: (
            <AuthGuard>
                <MainLayout>
                    <Orders />,
                </MainLayout>
            </AuthGuard>
        ),
    },
    {
        path: '*',
        children: [
            {
                path: '401',
                element: <AuthRequired />,
            },
            {
                path: '404',
                element: <NotFound />,
            },
            {
                path: '500',
                element: <ServerError />,
            },
            {
                path: '*',
                element: <Navigate to="/404" replace />,
            },
        ],
    },
]
