import {
    Box,
    Card,
    Chip,
    Container,
    Divider,
    Link,
    Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
import { JWTLogin } from '../../components/authentication/jwt-login'
import { gtm } from '../../lib/gtm'

const Login: FC = () => {
    useEffect(() => {
        gtm.push({ event: 'page_view' })
    }, [])

    return (
        <>
            <Helmet>
                <title>Login | Material Kit Pro</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        py: {
                            xs: '60px',
                            md: '120px',
                        },
                    }}
                >
                    <Card elevation={16} sx={{ p: 4 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h4">Log in</Typography>
                            <Typography
                                color="textSecondary"
                                sx={{ mt: 2 }}
                                variant="body2"
                            >
                                Sign in on the internal platform
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 3,
                            }}
                        >
                            <JWTLogin />
                        </Box>
                        <Divider sx={{ my: 3 }} />
                        <div>
                            <Link
                                color="textSecondary"
                                component={RouterLink}
                                to="/authentication/register"
                                variant="body2"
                            >
                                Request account
                            </Link>
                        </div>
                        <Box sx={{ mt: 1 }}>
                            <Link
                                color="textSecondary"
                                component={RouterLink}
                                to="/authentication/password-recovery"
                                variant="body2"
                            >
                                Forgot password
                            </Link>
                        </Box>
                    </Card>
                </Container>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    <Chip
                        label="Developed by KES-Soft"
                        color="primary"
                        component="a"
                        href="https://kes-soft.de"
                        clickable
                        target="_blank"
                        rel="noopener"
                    />
                </Box>
            </Box>
        </>
    )
}

export default Login
